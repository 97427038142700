'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import Select from '../../pro/components/Widgets/Select.react';
import UnitsMode from '../../pro/components/Widgets/UnitsMode.react';
import DateSelector from '../../pro/components/Widgets/DateSelector.react';
import ConfigWarning from '../Widgets/ConfigWarning.react';
import energyFormulas from '../../utils/EnergyCalculator';
import { cmToFeetInches } from '../../pro/utils/Patients';
import {  calculateNearestPortionSize}  from '../../tables/portions';
import Analytics from '../../utils/Analytics';
import modalStyles from '../../jsx-styles/modals';
import '../../pro/components/Modals/Modals.scss';
import '../MyAccount/PreferencesForm.scss';
import './EnergyEstimatorModal.scss';
import ActivityLevelSelector from '../Input/ActivityLevelSelector.react';

const mifflin = energyFormulas.filter(f => f.prop === 'mifflin')[0];

const heightFeetOpts = [
    {value: 3, label: '3 ft.'},
    {value: 4, label: '4 ft.'},
    {value: 5, label: '5 ft.'},
    {value: 6, label: '6 ft.'},
    {value: 7, label: '7 ft.'},
    {value: 8, label: '8 ft.'},
];

const heightInchOpts = [
    {value: 0, label: '0 in.'},
    {value: 1, label: '1 in.'},
    {value: 2, label: '2 in.'},
    {value: 3, label: '3 in.'},
    {value: 4, label: '4 in.'},
    {value: 5, label: '5 in.'},
    {value: 6, label: '6 in.'},
    {value: 7, label: '7 in.'},
    {value: 8, label: '8 in.'},
    {value: 9, label: '9 in.'},
    {value: 10, label: '10 in.'},
    {value: 11, label: '11 in.'},
];

export default class EnergyEstimatorModal extends Component {
    static propTypes = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        let {
            conditions = [],
            diets = [],
            avoidances = [],
            target_energy_kcal = '',
            activity_level = '',
            height_cm = '',
            weight_kg = '',
            goal_weight_kg = '',
            weekly_goal_kcal = '',
            units_mode = 'english',
            due_date = null,
            fetus_count = null,
            birthdate,
            gender,
            preferences
        } = props;

        const height = cmToFeetInches(height_cm || 0);

        if (!['english', 'metric'].includes(units_mode)) {
            units_mode = 'english';
        }

        if (target_energy_kcal == 2000) {
            target_energy_kcal = '';
        }

        this.state = {
            conditions,
            diets,
            avoidances,
            units_mode,
            birthdate,
            gender,
            height_cm,
            height_feet: height.feet,
            height_inches: height.inches,
            weight_kg,
            weight_lbs: weight_kg ? Math.round(weight_kg * 2.20462) : '',
            goal_weight_kg,
            goal_weight_lbs: Math.round(goal_weight_kg * 2.20462) || '',
            weekly_goal_kcal,
            activity_level,
            target_energy_kcal,
            due_date,
            fetus_count,
            dirty: false,
            preferences
        };
    }

    updateParent = () => {
        let {
            conditions,
            diets,
            avoidances,
            target_energy_kcal,
            activity_level,
            height_cm,
            weight_kg,
            goal_weight_kg,
            units_mode,
            due_date,
            fetus_count,
            birthdate,
            gender,
            weekly_goal_kcal
        } = this.state;

        const { onChangeValues } = this.props;

        if (!target_energy_kcal) {
            target_energy_kcal = 2000;
        }

        const portion = calculateNearestPortionSize(target_energy_kcal, this.props.portion_resolution) || 1;

        onChangeValues({
            conditions,
            diets,
            avoidances,
            target_energy_kcal,
            activity_level,
            height_cm,
            weight_kg,
            goal_weight_kg,
            weekly_goal_kcal,
            units_mode,
            due_date,
            fetus_count,
            birthdate,
            gender,
            portion,
            age: birthdate ? moment().diff(birthdate, 'years') : false,
        });
    }

    calc = async () => {
        const { conditions, gender, birthdate, weight_kg, height_cm, activity_level, goal_weight_kg } = this.state;

        let age = 32, ageDays = 32 * 365;
        if (!(gender && birthdate && weight_kg && height_cm && activity_level)) {
            this.updateParent();
            return;
        }

        if (moment(birthdate).isValid()) {
            age = moment().diff(birthdate, 'year');
            ageDays = moment().diff(birthdate, 'day');
        }

        const params = {
            gender,
            weightKg: weight_kg,
            heightCm: height_cm,
            activity: activity_level,
            age,
            ageDays,
        };

        let target_energy_kcal = Math.round(mifflin.compute(params));

        if (target_energy_kcal === false) {
            this.setState({target_energy_kcal: '', dirty: true}, this.updateParent);
            return;
        }

        if (conditions.includes('Pregnancy')) {
            // What trimester are we in, and how many fetus's do we have?
            const { due_date, fetus_count = 1 } = this.state;

            const weeksPregnant = moment().diff(moment(due_date).subtract(42, 'weeks'), 'weeks');

            // First Trimester - NO ADDITIONAL CALORIE NEEDED
            // Second Trimester
            if (weeksPregnant >= 14 && weeksPregnant <= 27) {
                // 340 kcal for the first baby, and 175 kcal for each additional
                target_energy_kcal += 340 + (fetus_count - 1) * 175;
            }

            // Third Trimester
            if (weeksPregnant >= 28) {
                target_energy_kcal += 450 + (fetus_count - 1) * 225;
            }
        } else if (goal_weight_kg) {
            if (goal_weight_kg > weight_kg * 1.025) {
                // Gain weight
                target_energy_kcal += 500;
            } else if (goal_weight_kg < weight_kg * .975) {
                // Lose weight
                target_energy_kcal -= 500;
            }
        }

        if (conditions.includes('Lactation')) {
            target_energy_kcal += 500;
        }

        if (age > 12 && target_energy_kcal < 1200) {
            target_energy_kcal = 1200;
        } else if (age <= 12 && target_energy_kcal < 900) {
            target_energy_kcal = 900;
        }

        this.setState({target_energy_kcal, dirty: true}, this.updateParent);
    }

    onChangeCondition = (conditionUuid) => {
        let { gender, avoidances } = this.state;
        const { nutritionPatterns } = this.props;
        const pattern = nutritionPatterns.find((pattern) => pattern.uuid === conditionUuid);

        if (pattern.name === 'Pregnancy' || pattern.name === 'Lactation') {
            gender = 'female';
        }

        this.setState({conditions: [{name: pattern.name, uuid: pattern.uuid}], avoidances, gender, dirty: true}, this.calc);
    }

    toggleUnitsMode = () => {
        let { units_mode } = this.state;
        units_mode = units_mode === 'metric' ? 'english' : 'metric';

        this.setState({units_mode, dirty: true}, this.updateParent);
    }

    onChangeHeightFeet = (height_feet) => {
        const { height_inches } = this.state;

        const feet = parseInt(height_feet) || 0,
              inches = parseInt(height_inches) || 0;

        const height_cm = Math.round(((feet * 12) + inches) * 2.54) || '';

        this.setState({height_feet, height_cm, dirty: true}, this.calc);
    }

    onChangeHeightInches = (height_inches) => {
        const { height_feet } = this.state;

        const feet = parseInt(height_feet) || 0,
              inches = parseInt(height_inches) || 0;

        const height_cm = Math.round(((feet * 12) + inches) * 2.54) || '';

        this.setState({height_inches, height_cm, dirty: true}, this.calc);
    }

    onChangeHeightCm = (ev) => {
        const height_cm = ev.target.value;

        const english = cmToFeetInches(height_cm) || {feet: '', inches: ''};

        this.setState({height_cm, height_feet: english.feet, height_inches: english.inches}, this.calc);
    }


    onChangeWeightLbs = (ev) => {
        const weight_lbs = ev.target.value;

        const weight_kg = (Math.round(weight_lbs * 0.453592 * 10) / 10) || '';

        this.setState({weight_lbs, weight_kg}, this.calc);
    }

    onChangeWeightKg = (ev) => {
        const weight_kg = ev.target.value;

        const weight_lbs = (Math.round(weight_kg / 0.453592 * 10) / 10) || '';

        this.setState({weight_kg, weight_lbs}, this.calc);
    }

    onChangeActivityLevel = (activity_level) => {
        this.setState({activity_level}, this.calc);
    }

    onChangeGoalWeightLbs = (ev) => {
        let { weight_kg, weekly_goal_kcal } = this.state;
        const goal_weight_lbs = ev.target.value;
        const goal_weight_kg = (Math.round(goal_weight_lbs * 0.453592 * 10) / 10)|| '';

        if (goal_weight_kg > weight_kg * 1.025) {
            // Gain weight
            weekly_goal_kcal = 500;
        } else if (goal_weight_kg < weight_kg * .975) {
            // Lose weight
            weekly_goal_kcal = -500;
        } else {
            weekly_goal_kcal = 0;
        }

        this.setState({goal_weight_lbs, goal_weight_kg, weekly_goal_kcal}, this.calc);
    }

    onChangeGoalWeightKg = (ev) => {
        let { weight_kg, weekly_goal_kcal } = this.state;
        const goal_weight_kg = ev.target.value;
        const goal_weight_lbs = (Math.round(goal_weight_kg / 0.453592 * 10) / 10) || '';

        if (goal_weight_kg > weight_kg * 1.025) {
            // Gain weight
            weekly_goal_kcal = 500;
        } else if (goal_weight_kg < weight_kg * .975) {
            // Lose weight
            weekly_goal_kcal = -500;
        } else {
            weekly_goal_kcal = 0;
        }

        this.setState({goal_weight_kg, goal_weight_lbs, weekly_goal_kcal}, this.calc);
    }

    onChangeTargetEnergyKcal = (ev) => {
        const target_energy_kcal = ev.target.value;

        this.setState({target_energy_kcal, dirty: true});
    }

    onChangeDueDate = (due_date) => {
        this.setState({due_date, dirty: true}, this.calc);
    }

    onChangeFetusCount = (fetus_count) => {
        this.setState({fetus_count, dirty: true}, this.calc);
    }

    onChangeGender = (gender) => {
        this.setState({gender, dirty: true}, this.calc);
    }

    onChangeBirthdate = (birthdate) => {
        this.setState({birthdate, dirty: true}, this.calc);
    }

    validate = () => {
        const { birthdate, gender, conditions, height_cm, weight_kg, activity_level, due_date, fetus_count } = this.state;
        const { nutritionPatterns } = this.props;

        const age = birthdate ? moment().diff(birthdate, 'years') : 25;

        if (!conditions.length) {
            return {error: 'conditions', alert: 'Please select a health condition / lifestyle'};
        }

        if (conditions.includes('Pregnancy') && !due_date) {
            return {error: 'due_date', alert: 'Please enter your due date'};
        }

        if (conditions.includes('Pregnancy') && moment().isAfter(due_date)) {
            return {error: 'due_date', alert: 'Due date must be in the future.'};
        }

        if (conditions.includes('Pregnancy') && !fetus_count) {
            return {error: 'fetus_count', alert: 'Please select how many you are expecting.'};
        }

        if (!birthdate) {
            return {error: 'birthdate', alert: 'Please enter your birthdate'};
        }

        if (!height_cm) {
            return {error: 'height', alert: 'Please enter your height'};
        }

        if (!weight_kg) {
            return {error: 'weight', alert: 'Please enter your current weight'};
        }

        if (!activity_level) {
            return {error: 'activity_level', alert: 'Activity Level is required'};
        }

        return true;
    }

    apply = () => {
        const { conditions, gender, birthdate, weight_kg, target_energy_kcal } = this.state;
        const { nutritionPatterns, resolve } = this.props;
        const pattern = nutritionPatterns.find((pattern) => pattern.uuid === conditions[0].uuid);

        let alert = false;

        if (true !== (alert = this.validate())) {
            Analytics.tryItOutEnergyError(alert);
            this.setState(alert);
            return;
        }

        resolve(
            {gender, birthdate, weight_kg, target_energy_kcal},
            pattern
        );

        const { completed } = this.props;

        if (!completed.includes('energy')) {
            completed.push('energy');
            this.props.onChangeValues({completed});
        }

        Analytics.saveTryItOutEnergy();
        this.props.closeModal(true);
    }

    render() {
        const { closeModal, nutritionPatterns, tryItMode, estimatorGeneratesPlan, preferences } = this.props;
        const { user, birthdate, height_cm, height_feet, height_inches, weight_kg, weight_lbs,
                goal_weight_kg, goal_weight_lbs, error, alert, working, due_date, fetus_count,
                activity_level, target_energy_kcal, units_mode, gender, conditions} = this.state;
        const pattern = nutritionPatterns.find((pattern) => pattern.uuid === conditions[0].uuid);
        const conditionsOpts = nutritionPatterns.map(c => ({label: c.special_name || c.consumer_name || c.name, value: c.uuid}));
        const condition = conditions[0].uuid;

        const portion = calculateNearestPortionSize(target_energy_kcal, this.props.portion_resolution) || 1;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Energy Estimator"
                className="el-modal el-modal2 energy-estimator-modal"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal2-container energy-estimator-container  preferences-form">
                    <header>
                        {tryItMode === 'consumer' ?
                            <h2>{estimatorGeneratesPlan ? 'Just a few more questions...' : 'Calculate Your Calorie Needs'}</h2>
                        : null}
                        {tryItMode === 'pro' ? <h2>Calculate Calorie Needs</h2> : null}

                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>
                    <div className="el-modal-body-container el-modal2-body-container el-fonts">
                        {alert ?
                            <p data-active="true" className="error-msg">{alert}</p>
                        : <ConfigWarning profile={{
                            conditions: conditions.map(name => ({name})),
                            preferences,
                            birthdate,
                            target_energy_kcal,
                            portion,
                            height_cm,
                            weight_kg,
                            goal_weight_kg,
                            role: 'admin'
                        }} />}
                        {!estimatorGeneratesPlan ?
                            <div className="with-label conditions">
                                <label>Health Condition / Lifestyle</label>
                                <Select options={conditionsOpts}
                                    value={condition}
                                    onChange={this.onChangeCondition}
                                    placeholder="Choose a condition..." />
                            </div>
                        : null}

                        {pattern ? <p className="description">{pattern.description}</p> : null}

                        {condition == 'Pregnancy' ?
                            <div className="with-label due-date">
                                <label>Due Date</label>
                                <DateSelector initialDate={due_date} onChange={this.onChangeDueDate} delta_years={1}
                                    data-error={error === 'due_date'} />
                            </div>
                        : null}

                        {condition == 'Pregnancy' ?
                            <div className="with-label how-many-babies" data-error={error === 'fetus_count'}>
                                {tryItMode === 'consumer' ? <label>How many are you expecting?</label> : null}
                                {tryItMode === 'pro' ? <label>How many fetus?</label> : null}

                                <div className="toggle-container">
                                    <button className="toggle-btn male-btn" data-active={fetus_count == 1} onClick={() => this.onChangeFetusCount(1)}>
                                        Single
                                    </button>
                                    <button className="toggle-btn female-btn" data-active={fetus_count == 2} onClick={() => this.onChangeFetusCount(2)}>
                                        Twins
                                    </button>
                                </div>
                            </div>
                        : null}

                        {!['Pregnancy', 'Lactation'].includes(condition) ?
                            <div className="with-label gender">
                                <label>Birth Sex</label>

                                <div className="toggle-container">
                                    <button className="toggle-btn female-btn" data-active={gender == 'female'} onClick={() => this.onChangeGender('female')}>
                                        <i className="icon-female-sign" />Female
                                    </button>
                                    <button className="toggle-btn male-btn" data-active={gender == 'male'} onClick={() => this.onChangeGender('male')}>
                                        <i className="icon-male-sign" />Male
                                    </button>
                                </div>
                            </div>
                        : null}

                        <div className="with-label birthdate">
                            <label>{tryItMode === 'consumer' ? 'Your Birth Date' : 'Patient Birth Date'}</label>
                            <DateSelector initialDate={birthdate} onChange={this.onChangeBirthdate}
                                data-error={error === 'birthdate'} />
                        </div>

                        <UnitsMode value={units_mode}
                            onClick={this.toggleUnitsMode} />

                        <div className="with-label height">
                            <label>Height</label>

                            {units_mode === 'english' ?
                                <Select className="height-feet"
                                    value={height_feet}
                                    placeholder="feet"
                                    options={heightFeetOpts}
                                    data-error={error === 'height'}
                                    onChange={this.onChangeHeightFeet}>
                                    <p>feet.</p>
                                </Select>
                            : null}

                            {units_mode === 'english' ?
                                <Select className="height-inches"
                                    value={height_inches}
                                    placeholder="inches"
                                    options={heightInchOpts}
                                    data-error={error === 'height'}
                                    onChange={this.onChangeHeightInches}>
                                    <p>inches.</p>
                                </Select>
                            : null}

                            {units_mode === 'metric' ?
                                <div className="with-units height-cm">
                                    <input type="number" placeholder="Height"
                                        value={height_cm}
                                        data-error={error === 'height'}
                                        onChange={this.onChangeHeightCm} />

                                    <label>cm.</label>
                                </div>
                            : null}

                        </div>

                        <div className="with-label weight">
                            {condition == 'Pregnancy'
                                ? <label>Pre-Pregnancy Weight</label>
                                : <label>Current Weight</label>
                            }

                            {units_mode === 'english' ?
                                <div className="with-units weight-lbs">
                                    <input type="number"
                                        value={weight_lbs}
                                        data-error={error === 'weight'}
                                        onChange={this.onChangeWeightLbs} />
                                    <label>Lbs.</label>
                                </div>
                            : null}

                            {units_mode === 'metric' ?
                                <div className="with-units weight-kg">
                                    <input type="number"
                                        value={weight_kg}
                                        data-error={error === 'weight'}
                                        onChange={this.onChangeWeightKg} />

                                    <label>Kg.</label>
                                </div>
                            : null}
                        </div>

                        {(condition != 'Pregnancy' && condition != 'Lactation') ?
                            <div className="with-label goal-weight">
                                <label>Goal Weight <em>(optional)</em></label>
                                {units_mode === 'english' ?
                                    <div className="with-units">
                                        <input type="number" value={goal_weight_lbs}
                                            onChange={this.onChangeGoalWeightLbs} />
                                        <label>lbs.</label>
                                    </div>
                                : null}

                                {units_mode === 'metric' ?
                                    <div className="with-units">
                                        <input type="number" value={goal_weight_kg}
                                            onChange={this.onChangeGoalWeightKg} />
                                        <label>kg.</label>
                                    </div>
                                : null}
                            </div>
                        : null}

                        <ActivityLevelSelector
                            value={activity_level}
                            options={mifflin.activity_levels}
                            onChange={this.onChangeActivityLevel}
                            className={'activity-level'}
                            showAbove
                            title="Activity Level"
                            error={error == 'activity_level'} />

                        <div className="with-label target-energy-kcal" data-error={error === 'target_energy_kcal'}>
                            <label>Estimated Calories Per Day: </label>

                            <div className="number">{target_energy_kcal || <span>&mdash;</span>}</div>
                        </div>
                    </div>
                    <footer>

                        <button disabled={working} className="el-modal-ok-btn" onClick={this.apply}>
                                Done
                        </button>
                    </footer>

                </div>
            </Modal>
        );
    }
}
