'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import $ from 'jquery';
import platform from 'platform';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/react';

import LoginModal from '../components/Planner/Modals/LoginModal.react';
import Header from './components/Header.react';
import CookieConsentBanner from '../components/Widgets/CookieConsentBanner.react';
import PageMeta from '../components/Widgets/PageMeta.react';
import UpgradeMembershipModal from '../components/MyAccount/UpgradeMembershipModal.react';

import UserStore from '../stores/UserStore';

import Analytics from '../utils/Analytics';

// layout styles are in OffCanvas.scss
import "../../sass/style.scss";
import "./Root.scss";

if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
}

@DragDropContext(HTML5Backend)
export default class Root extends Component {

    static childContextTypes = {
        confirm: PropTypes.func,
        showLoginForm: PropTypes.func,
        showUpgradeForm: PropTypes.func,
        isMobile: PropTypes.bool,
        breakpoint47em: PropTypes.bool,
        viewportWidth: PropTypes.number,
        isCordova: PropTypes.bool,
        isPro: PropTypes.bool,
        iPad: PropTypes.bool,
        iPhone: PropTypes.bool,
        isAndroid: PropTypes.bool,

        location: PropTypes.object,
        user: PropTypes.object,
        showLoginForm: PropTypes.func,
    }

    constructor(props) {
        super(props);

        let viewportWidth = process.browser ? $(window).width() : 1024;
        const user = UserStore.getUser();

        this.state = {
            user,
            isMenuOpen: false,

            // Login form options
            loginIsOpen: false,
            loginFormOptions: {formSubtitleText: 'Sign in to access your patients and recommended meal plans.'},

            // Confirmation dialog state
            confirmDialogVisible: false,
            confirmDialogTitle: '',
            confirmDialogContents: 'Are you sure?',
            confirmDialogAcceptText: 'Ok',
            confirmDialogAccept: null,
            confirmDialogRejectText: 'Cancel',
            confirmDialogReject: null,
            ...this.getStateFromProps(props, user),
            ...this.getDeviceParams(),

            upgradeForm: null,

            lastLocation: props.location.pathname,
            isDisconnected: false,
        };
    }

    getChildContext = () => {
        const { user, isMobile, viewportWidth, isCordova, iPad, iPhone, isAndroid } = this.state;
        const { location } = this.props;

        return {
            confirm: this.confirm,
            showUpgradeForm: this.showUpgradeForm,
            isMobile,
            showLoginForm: this.showLoginForm,
            breakpoint47em: !isMobile,
            viewportWidth,
            isCordova,
            iPad,
            iPhone,
            isAndroid,

            location,
            user,
            showLoginForm: this.showLoginForm,
            isPro: true,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        try {
            Analytics.trackUncaughtException(error, errorInfo);
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        } catch (exp) {
            // we threw an additional exception, ignore it, nothing we can do.
        }
    }

    getStateFromProps = (props, user) => {
        const { location, routes } = props;

        let showHeader = true;
        let showSignUp = true;
        let showNavigation = true;

        if (!(user && user.practice) && location.pathname === '/') {
            showHeader = false;
        }

        const { hideNavigation } = routes[routes.length - 1] || {};

        if (hideNavigation) {
            showHeader = false;
        }

        if (location.pathname.includes('/new-account')) {
            showSignUp = false;
            showNavigation = false;
        }

        if (location.pathname.includes('/menus/') && !user) {
            showSignUp = false;
        }

        return {
            showSignUp,
            showNavigation,
            showHeader,
        }
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);

        if (!process.browser) {
            return;
        }

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('offline', () => this.setState({isDisconnected: true}));
        window.addEventListener('online', () => this.setState({isDisconnected: false}));

        // Try to detect if we're in Safari Private mode. Safari private mode sets a 0 byte quote on localstorage
        // so we can detect that. We only want to run this when we're sure we're in Safari, because it's possible
        // that spiders won't work (but they don't need localstorage to work)
        try {
            if (platform.name === 'Safari' && window.localStorage) {
                localStorage.test = 1
                delete localStorage.test;
            }
        } catch (e) {
            this.setState({safariPrivateIncompat: true});
        }
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);

        if (!process.browser) {
            return;
        }

        window.removeEventListener('resize', this.handleResize);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { location } = nextProps;
        const { lastLocation } = this.state;

        this.setState({
            loginIsOpen: this.state.loginIsOpen && (location.pathname === lastLocation),
            lastLocation: location.pathname,
        });
    }

    onUserStoreChange = () => {
        const user = UserStore.getUser();

        this.setState({
            user,
            loginIsOpen: user ? false : this.state.loginIsOpen,
            ...this.getStateFromProps(this.props, user)
        });
    }

    showLoginForm = (loginFormOptions = {}) => {
        this.setState({loginIsOpen: true, loginFormOptions});
    }

    rootLayout = null;

    getDeviceParams = () => {
        let viewportWidth = $(window).width(),
            viewportHeight = $(window).height();

        // Are we on an iPad? We're always mobile on an iPad
        const ua = navigator.userAgent;
        const iPad = /iPad/i.test(ua), iPhone = /iPhone/i.test(ua);
        const breakpoint47em = viewportWidth > 751;
        const isMobile = viewportWidth < 751;
        const isCordova = window.cordova ? true : false;
        const isAndroid = platform.os.family === 'Android';

        return { viewportWidth, viewportHeight, isMobile, iPad, iPhone, isAndroid, isCordova, breakpoint47em };
    }

    handleResize = () => {
        if (!process.browser) {
            return;
        }

        const deviceParams = this.getDeviceParams();

        this.setState(deviceParams);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState(this.getStateFromProps(nextProps, UserStore.getUser()));
    }

    confirm = (contents, acceptCallback, rejectCallback, userOpts = {}) => {
        const opts = {
            acceptText: 'Ok',
            rejectText: 'Cancel',
            titleText: '',
            ...userOpts,
        };

        this.setState({
            user: UserStore.getUser(),
            confirmDialogVisible: true,
            confirmDialogTitle: opts.titleText,
            confirmDialogContents: contents,
            confirmDialogAcceptText: opts.acceptText,
            confirmDialogAccept: acceptCallback,
            confirmDialogRejectText: opts.rejectText,
            confirmDialogReject: rejectCallback,
            confirmDialogRejectDefaultClass: opts.rejectDefaultClass,
        });
    }

    closeModal = () => {
        this.setState({
            confirmDialogVisible: false,
            upgradeForm: null,
            loginIsOpen: false,
        });
    }

    showUpgradeForm = (opts = {}) => {
        const { user } = this.state;
        const { feature } = opts;

        if (!user) {
            return this.showLoginForm();
        }

        this.setState({upgradeForm: {feature}});
    }

    toggleNavigation = () => {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    }

    confirmDialogReject = () => {
        const { confirmDialogReject } = this.state;

        this.closeModal();

        confirmDialogReject && confirmDialogReject();
    }

    confirmDialogAccept = () => {
        const { confirmDialogAccept } = this.state;

        this.closeModal();

        confirmDialogAccept && confirmDialogAccept();
    }

    renderUpgradeForm = () => {
        const { upgradeForm } = this.state;


        if (!upgradeForm) {
            return;
        }

        return (
            <UpgradeMembershipModal {...upgradeForm} closeModal={this.closeModal} />
        );
    }

    renderConfirmDialog = () => {
        const { confirmDialogVisible, confirmDialogContents, confirmDialogTitle,
                confirmDialogAcceptText, confirmDialogRejectText, confirmDialogRejectDefaultClass } = this.state;
        const { location } = this.props;

        if (!confirmDialogVisible) {
            return null;
        }

        return (
            <Modal isOpen
                onRequestClose={confirmDialogRejectText ? this.confirmDialogReject : this.confirmDialogAccept}
                closeModal={() => false}
                contentLabel="Confirmation"
                className="el-modal el-modal3 el-modal3-centered"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>

                <section className="el-modal-container el-modal3-container">
                    {confirmDialogTitle ?
                        <header><h2>{confirmDialogTitle}</h2></header>
                    : null}

                    <div className="el-modal-body-container el-modal3-body-container el-fonts">
                        {typeof confirmDialogContents === 'string' ?
                            <p className="p3">{confirmDialogContents}</p>
                        : null}

                        <div className="el-confirmation">
                            {confirmDialogContents && typeof confirmDialogContents !== 'string' ?
                                confirmDialogContents
                            : null}
                        </div>
                    </div>

                    <footer>
                        {confirmDialogRejectText ?
                            <button className={confirmDialogRejectDefaultClass || "el-modal-cancel-btn"} onClick={this.confirmDialogReject}>{confirmDialogRejectText}</button>
                        : null}

                        {confirmDialogAcceptText ?
                            <button className="el-modal-ok-btn" onClick={this.confirmDialogAccept}>{confirmDialogAcceptText}</button>
                        : null}
                    </footer>
                </section>
            </Modal>
        );
    }

    renderLoginModal = () => {
        const { loginIsOpen, loginFormOptions } = this.state;

        if (!loginIsOpen) {
            return null;
        }

        return (
            <LoginModal closeModal={loginFormOptions.inhibitCloseModal ? null : this.closeModal}
                loginFormOptions={loginFormOptions}
                offModalCta={loginFormOptions.offModalCta} />
        );
    }

    render = () => {
        const { page, isMenuOpen, isMobile, showSignUp, showNavigation, showHeader,
                hasError, safariPrivateIncompat, isDisconnected } = this.state;
        const { location, routes } = this.props;

        if (hasError) {
            return (
                <div className="error-loading-eatlove">
                    <img src="https://static.chewba.info/images/logo-icon-2018-08.png" />
                    <p>We're very sorry, but EatLove PRO has encountered an error. Please try again!</p>
                    <p>Our technicians have been notified and are working the issue!</p>
                    <p>Need help? Please email us at <a href="mailto:support@eatlove.is?subject=EatLove Crashed">support@eatlove.is</a></p>

                    <footer>
                        <button onClick={() => window.location.reload(true)}>reload</button>
                    </footer>
                </div>
            );
        }

        if (safariPrivateIncompat) {
            return (
                <div className="error-loading-eatlove">
                    <img src="https://static.chewba.info/images/header-logo-gray-green-2017-07-03.png" />
                    <p>We're very sorry, but EatLove is not compatible with Safari's Private browsing mode.</p>
                    <p>Please turn off private browsing mode. Chrome incognito or Firefox private window will work also.</p>
                    <p>Need help? Please email us at <a href="mailto:support@eatlove.is?subject=Safari Private Mode">support@eatlove.is</a></p>
                </div>
            );
        }

        return (
            <div className="root-layout pro-layout" data-page={page} data-has-header={showHeader} data-offcanvas={isMenuOpen}>
                <PageMeta />

                {showHeader ?
                    <Header ref="header"
                        toggleNavigation={this.toggleNavigation}
                        showSignUp={showSignUp}
                        showNavigation={showNavigation}
                        isDisconnected={isDisconnected} />
                : null}

                <div className="children">
                    {this.props.children}
                </div>

                <CookieConsentBanner />

                {this.renderConfirmDialog()}
                {this.renderUpgradeForm()}
                {this.renderLoginModal()}
            </div>
        );
    }
}
