'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import Select from '../../Widgets/Select.react';
import Working from '../../Patients/Editor/Working.react';
import ImgResized from '../../../../components/Widgets/ImgResized.react';

import { hidePatientFor } from '../../../utils/Patients';
import { getConfig } from '../../../../utils/Env';
import AuthStore from '../../../../stores/AuthStore';

import './NotNowModal.scss';
import modalStyles from '../../../../jsx-styles/modals';

export default class NotNowModal extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);

        const { patient } = props;

        this.state = {
            remind_me: 7,
            following: patient.following,
            working: false,
        };
    }

    remindMeLater = () => {
        // Are we disabling following?
        const { remind_me, following } = this.state;
        const { patient, closeModal } = this.props;

        // Simple. Add a "not-now" record to localstorage with an expiration.
        hidePatientFor(patient, remind_me);

        if (!following) {
            // We need to completely unfollow the patient.
            this.setState({working: true});

            AuthStore.fetch(getConfig('users_api') + patient.links.self.href, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; schema=patient/1',
                },
                body: JSON.stringify({following}),
            }).then(
                response => {
                    closeModal();
                },
                error => {
                    this.setState({working: false, error: error.message});

                    reject(error);
                }
            );
        } else {
            (getConfig('users_api') + patient.links.self.href)
            closeModal();
        }
    }

    render() {
        const { patient, closeModal } = this.props;
        let { working, following, remind_me } = this.state;

        const remindMeOps = [
            {value: 1, label: '1 day'},
            {value: 2, label: '2 days'},
            {value: 3, label: '3 days'},
            {value: 4, label: '4 days'},
            {value: 5, label: '5 days'},
            {value: 6, label: '6 days'},
            {value: 7, label: '7 days'},
            {value: 14, label: '2 weeks'},
            {value: 21, label: '3 weeks'},
            {value: 28, label: '4 weeks'},
        ];

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="not-now-modal"
                contentLabel="Dismiss Patient"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="not-now-modal-container">
                    {!working ?
                        <header className="modal-header">
                            <button className="close-btn" onClick={closeModal}>
                                <i className="icon-close-x" />
                                <span className="assistive-text">Close Modal</span>
                            </button>
                        </header>
                    : null}

                    {!working ?
                        <div>
                            <div className="modal-body patient-form">
                                <div className="patient-info">
                                    <div className="patient-image">
                                        {patient.image
                                            ? <ImgResized src={patient.image} width="120" height="120" />
                                            : <i className={patient.gender === 'female' ? 'icon-female2' : 'icon-male2'} />}
                                    </div>

                                    <h2>{patient.first_name} {patient.last_name}</h2>
                                    <p>Patient #: <em>{patient.patient_number || '12345'}</em></p>
                                </div>

                                <Select className="remind-me"
                                    value={remind_me}
                                    prefix={<em>Remind me in: </em>}
                                    options={remindMeOps}
                                    onChange={remind_me => this.setState({remind_me})}>
                                    <p>Days to ignore</p>
                                </Select>

                                <button className="checkbox-btn" data-checked={!following} onClick={() => this.setState({following: !following})}>
                                    I am no longer following this patient
                                </button>
                            </div>

                            <footer className="modal-footer">
                                <button className="cancel" onClick={closeModal}>Cancel</button>
                                <button className="submit" onClick={this.remindMeLater}>Submit</button>
                            </footer>
                        </div>
                    : null}

                    {working ?
                        <Working title="Saving patient..." message="Unfollowing this patient." />
                    : null}
                </div>
            </Modal>
        );
    }
}
